/* eslint-disable new-cap */
import _ from 'lodash'
import React, { useLayoutEffect, useRef } from 'react'
import './scroll-section-style.scss'
import LocomotiveScroll from 'locomotive-scroll'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const ScrollSection = ({ data }) => {
  const { acf } = data
  const scrollRef = useRef(null)
  const information = _.get(acf, 'text_image', undefined)

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const locoScroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      multiplier: 2,
      smoothMobile: true,
    })
    locoScroll.on('scroll', ScrollTrigger.update)

    ScrollTrigger.scrollerProxy(scrollRef.current, {
      scrollTop(value) {
        return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y
      },
      getBoundingClientRect() {
        return {
          top: 0, left: 0, width: window.innerWidth, height: window.innerHeight,
        }
      },

      pinType: scrollRef.current.style.transform ? 'transform' : 'fixed',
    })

    gsap.set('.panel', { zIndex: (i, target, targets) => targets.length - i })

    const images = gsap.utils.toArray('.panel:not(.purple)')

    images.forEach((image, i) => {
      const tl = gsap.timeline({

        scrollTrigger: {
          trigger: 'section.black',
          scroller: scrollRef.current,

          start: () => `top -${window.innerHeight * (i)}`,

          end: () => `+=${window.innerHeight}`,
          scrub: true,
          toggleActions: 'play none reverse none',
          invalidateOnRefresh: true,
        },

      })

      tl
        .fromTo(image, { height: () => '100%' }, { height: () => '0%', ease: 'none' })
    })

    ScrollTrigger.create({

      trigger: 'section.black',
      scroller: scrollRef.current,
      // markers: true,

      /*---*/
      pin: '.p-wrap',

      start: () => 'top top',
      end: () => `+=${(images.length) * window.innerHeight}`,
      invalidateOnRefresh: true,

    })

    ScrollTrigger.addEventListener('refresh', () => locoScroll.update())
    ScrollTrigger.refresh()
    return () => {
      ScrollTrigger.killScroll()
    }
  }, [])

  return (
    <div className="scroller" ref={scrollRef}>

      <section className="orange">
        <div className="text">This is some text inside of a div block.</div>
      </section>

      <section className="black">

        <div className="text-wrap">
          <div className="text-wrapper">
            <div className="panel-text blue-text">Blue</div>
            <div className="panel-text red-text">Red</div>
            <div className="panel-text orange-text">Orange</div>
            <div className="panel-text purple-text">Purple</div>
          </div>
        </div>

        <div className="p-wrap">
          <div className="panel blue">
            {
              information && <div>
                <img src={information[0].image} />
              </div>
            }
          </div>
          <div className="panel red"></div>
          <div className="panel orange"></div>
          <div className="panel purple"></div>
        </div>

      </section>

      <section className="blue"></section>

    </div>

  )
}

export default ScrollSection
